<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr>
              <td>
                <v-progress-circular
                  size="40"
                  value="75"
                  color="red"
                  class="caption"
                >
                  75%
                </v-progress-circular>
              </td>
              <td>
                Porcentaje de preguntas contestadas del checklist (con anomalías)
              </td>
            </tr>
            <tr>
              <td>
                <v-progress-circular
                  size="40"
                  value="100"
                  color="green"
                  class="caption"
                >
                  100%
                </v-progress-circular>
              </td>
              <td>
                Porcentaje de preguntas contestadas del checklist (todo correcto)
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  label
                >
                  123
                </v-chip>
              </td>
              <td>
                Nº de elemento
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  label
                  text-color="white"
                  color="green"
                >
                  REV
                </v-chip>
              </td>
              <td>
                Acción REV realizada
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  label
                  text-color="white"
                  color="red"
                >
                  SUST
                </v-chip>
              </td>
              <td>
                Acción SUST pendiente
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin]
}
</script>
